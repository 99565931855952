import {
	model, Model, modelAction, modelFlow, prop, _async, _await,
} from 'mobx-keystone';
import { decode } from 'html-entities';

import {
	buildPlayEventTrackingData, formatSecondsToMinutesAndSeconds, getMilestoneEventTrackingDataOrNull, getThumbnail, hasTrackedMilestoneEvent, hasTrackedPlayEvent, wistiaRequest,
} from '~/components/wistia-player/Utils/WistiaPlayer.utils';
import { LinkEventStore } from '~/tracking/link-event/Stores/LinkEvent.store';
import { IWistiaOEmbedData, IWistiaVideoMedia, VideoMilestoneEvent } from '~/components/wistia-player/Types/WistiaPlayer.type';
import { isEngage } from '~/global/global.constants';
import { MagicModalStore } from '~/components/magic-modal/Stores/MagicModal.store';
import { openVideoInModal } from '~/components/wistia-player/Utils/WistiaPlayerModal.utils';
import { ShowcaseVariant, ThumbnailSize } from '~/components/wistia-player/Types/constants';

@model('/WistiaPlayer/WistiaPlayerStore')
export class WistiaPlayerStore extends Model({
	duration: prop<string>(''),
	milestonesAlreadyReached: prop<VideoMilestoneEvent[]>(() => []),
	showcaseVariant: prop<ShowcaseVariant>(ShowcaseVariant.Standard),
	thumbnail: prop<string>(''),
	title: prop<string>('').withSetter(),
	videoData: prop<IWistiaOEmbedData | undefined>().withSetter(),
	videoId: prop<string>(''),
}) {
	linkEventStore: LinkEventStore | null = null;

	magicModal: MagicModalStore | null = null;

	isMediaQueryMd: boolean = true;

	@modelFlow
	getVideoData = _async(function* (this: WistiaPlayerStore, thumbnailSize: ThumbnailSize = ThumbnailSize.Default) {
		const response = yield* _await(wistiaRequest(this.videoId));

		if (response?.data) {
			this.thumbnail = getThumbnail(response.data.thumbnail_url, thumbnailSize);
			this.duration = formatSecondsToMinutesAndSeconds(response.data.duration);

			this.setVideoData(response.data);
			this.setTitle(decode(response.data.title));
		}
	});

	buildVideoPlayEventData = (compName: string) => {
		return buildPlayEventTrackingData(compName, this.videoId);
	};

	trackVideoPlayEvent = (compName: string, eventDataLayer: any[] = []) => {
		if (!this.linkEventStore) {
			return;
		}

		const playEventTrackingData = this.buildVideoPlayEventData(compName);
		const hasAlreadyTrackedPlayEvent = hasTrackedPlayEvent(compName, eventDataLayer);

		if (!hasAlreadyTrackedPlayEvent) {
			this.linkEventStore.trackVideo(playEventTrackingData as any);
		}
	};

	buildVideoMilestoneEventData = (
		compName: string,
		percentWatched: number,
		lastPercentWatched: number,
	) => {
		const pageID = this.linkEventStore?.model?.pageID || '';
		const milestoneEventTrackingData = getMilestoneEventTrackingDataOrNull(pageID, compName, percentWatched, lastPercentWatched, this.milestonesAlreadyReached, isEngage);

		return milestoneEventTrackingData;
	};

	@modelAction
	trackVideoMilestoneEvent = (
		compName: string,
		percentWatched: number,
		lastPercentWatched: number,
		eventDataLayer: any[] = [],
	) => {
		const milestoneEventTrackingData = this.buildVideoMilestoneEventData(compName, percentWatched, lastPercentWatched);

		if (milestoneEventTrackingData) {
			this.milestonesAlreadyReached = [...this.milestonesAlreadyReached, milestoneEventTrackingData.event];

			const hasAlreadyTrackedMilestoneEvent = hasTrackedMilestoneEvent(milestoneEventTrackingData.event, compName, eventDataLayer);

			if (!hasAlreadyTrackedMilestoneEvent && eventDataLayer) {
				eventDataLayer.push(milestoneEventTrackingData);
			}
		}
	};

	openModal = () => {
		openVideoInModal(this);
	};

	@modelAction
	setMedia = (media: IWistiaVideoMedia) => {
		this.setTitle(media.name);

		this.thumbnail = getThumbnail(media?.embedOptions?.unalteredStillImageAsset?.url || '');
		this.duration = formatSecondsToMinutesAndSeconds(media.duration);
	};
}

export const createWistiaPlayerStore = (
	videoId: string,
	linkEventStore: LinkEventStore | null = null,
	magicModal: MagicModalStore | null = null,
	isMediaQueryMd: boolean = true,
	showcaseVariant: ShowcaseVariant = ShowcaseVariant.Standard,
) => {
	const store = new WistiaPlayerStore({ videoId, showcaseVariant });

	store.linkEventStore = linkEventStore;
	store.magicModal = magicModal;
	store.isMediaQueryMd = isMediaQueryMd;

	return store;
};
