import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { TextLink } from '~/components/Buttons/Components/TextLink';

import { MagicHeaderTag } from '~/components/magic-header';
import { noop } from '~/util/noop';

export const FdsSweepsPromo = ({ clickHandler = noop }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-[#5E371E] tw-flex tw-justify-center tw-text-center tw-px-6 md:tw-px-4 tw-py-10 tw-mb-1">
				<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px] md:tw-space-y-[20px]">
					<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white">Free Design Help and a Chance to Win $4,000</MagicHeaderTag>
					<span className="tw-body-2 tw-text-white">Now through May 18, meet with a Design Associate in store, online or by phone and you’ll be entered for a chance to win a $4,000 Room & Board gift card.*</span>
					<Link
						className=""
						color={ButtonColor.White}
						href="/free-design-services/schedule-your-free-appointment"
						isTransparent
						variant={ButtonVariant.Hollow}
						onClick={clickHandler}
					>
					Make a Free Appointment
					</Link>
				</div>
			</div>
			<p className="tw-text-gray-300 md:tw-text-2xs tw-text-xs md:tw-text-center">*Void where prohibited. No purchase necessary to enter. Subject to <TextLink href="https://woobox.com/wgixqj/rules" target="_blank" linkExternal={true} ><span className="md:tw-text-2xs tw-text-xs">Official Rules</span></TextLink>.</p>
		</>
	);
};
