import { action, computed, observable, makeObservable } from 'mobx';
import Cookies from 'js-cookie';

import { addToUrl } from '~/util/addToUrl';
import { RecoItemModel } from '~/components/magic-recos-rfk/Models/RecoItem.model';
import { difference } from '~/util/difference';

export class MagicRecosRfkModel {
	accessToken = undefined;

	contentFields = undefined;

	forceNonSkuImage = undefined;

	hasRecos = undefined;

	// @deprecated
	mapRfkFieldsToLegacyModel(items) {
		// Map RFK fields to MagicReco model.
		const result = items.map((item) => {
			const theItem = {};

			Object.entries(this.KEY_MAP).forEach((entry) => {
				const [key, value] = entry;

				if (!item[value]) {
					return;
				}
				theItem[key] = item[value];
			});
			return theItem;
		});

		// Modify images
		return result.map((item) => {
			const width = 160;
			const height = 85;
			const preset = '$150w$';

			return {
				...item,
				...(item.image_link && {
					image_link: addToUrl(item.image_link, `wid=${width}&hei=${height}&${preset}`),
					image_link_2x: addToUrl(item.image_link, `wid=${width * 2}&hei=${height * 2}&${preset}`),
				}),
			};
		});
	}

	// @deprecated
	// Keys are for MagicRecos, values are coming from RFK.
	KEY_MAP = {
		image_link: 'image_url',
		link: 'url',
		name: 'name',
		num_reviews: 'rating_count',
		rating: 'review_rating',
		regular_price: 'price',
		regular_price_max: 'price_ranges',
		sale_price: 'final_price',
	};

	maxItems = undefined;

	recosData = undefined;

	get recoItems() {
		const items = this.recosData?.content?.product?.value;
		let useSkuImage = false;

		if (!items?.length) {
			return [];
		}

		useSkuImage = !this.forceNonSkuImage;

		// Uncomment to use legacy MagicReco model/component.
		// return this.mapRfkFieldsToLegacyModel(items);
		return items.map((item, index) => new RecoItemModel(item, index, useSkuImage));
	}

	setRecosData(recosData) {
		this.recosData = recosData;
	}

	setHasRecos(hasRecos) {
		this.hasRecos = hasRecos;
	}

	rid = undefined;

	setSku(articleNumbers) {
		// Do not attempt to set if there is no difference, thus, ignoring different sequence of the same skus.
		if (
			(articleNumbers === null && this.sku === null)
			|| (articleNumbers != null && this.sku != null
				&& articleNumbers.length === this.sku.length && !difference(articleNumbers, this.sku).length)
		) {
			return;
		}
		this.sku = articleNumbers;
	}

	sku = undefined;

	stubData = {
		'total_item': 20,
		'widget': {
			'rfkid': 'rfkid_4',
		},
		'variation_id': 'variation_id1324952632',
		'rid': 'response_id:66078253:335850a096dcc34dfd49d3ce8ea33a3496b52a55',
		'dt': 45,
		'err': null,
		'url': '?data%3D%7B%22content%22%3A%20%7B%22product%22%3A%20%7B%22field%22%3A%20%5B%22sku%22%2C%20%22rating_count%22%2C%20%22leadtime_text%22%2C%20%22price_ranges%22%2C%20%22name%22%2C%20%22image_url%22%2C%20%22url%22%2C%20%22review_rating%22%2C%20%22title%22%2C%20%22price%22%2C%20%22final_price%22%5D%2C%20%22max%22%3A%2010%7D%7D%2C%20%22widget%22%3A%20%7B%22rfkid%22%3A%20%22rfkid_4%22%7D%2C%20%22page_number%22%3A%201%2C%20%22n_item%22%3A%2010%2C%20%22context%22%3A%20%7B%22page%22%3A%20%7B%22uri%22%3A%20%22/shop/shoppingcart/cartdetails.do%22%7D%2C%20%22user%22%3A%20%7B%22user_id%22%3A%20%22123%22%2C%20%22uuid%22%3A%20%22123%22%7D%7D%7D',
		'ts': 1596203276592,
		'content': {
			'product': {
				'total_item': 20,
				'value': [
					{
						'sku': '27292',
						'name': 'Abra Pendants',
						'url': '/catalog/lighting/pendant-lights-and-chandeliers/abra-pendants',
						'price': '349.00',
						'price_ranges': '$1,197 - $1,995',
						'review_rating': null,
						'image_url': '//rnb.scene7.com/is/image/roomandboard/363594',
						'final_price': '329.00',
						'promotion_flag': 'Clearance',
					},
					{
						'sku': '27294',
						'name': 'Abra Pendant Sets, Group of 3 or 5',
						'url': '/catalog/lighting/pendant-lights-and-chandeliers/abra-pendant-sets-group-of-3-or-5',
						'price': '0.01',
						'price_ranges': null,
						'review_rating': null,
						'image_url': '//rnb.scene7.com/is/image/roomandboard/?layer=0&src=418774_2018_color_BPGP&layer=1&src=418774_2018_color_clr_mix&layer=comp',
						'final_price': '0.01',
					},
					{
						'sku': '27293',
						'name': 'Abra Pendant Sets, Row of 3 or 5',
						'url': '/catalog/lighting/pendant-lights-and-chandeliers/abra-pendant-sets-row-of-3-or-5',
						'price': '1995.00',
						'price_ranges': null,
						'review_rating': 4,
						'rating_count': '1',
						'image_url': '//rnb.scene7.com/is/image/roomandboard/?layer=0&src=817226_2018_color_SS&layer=1&src=817226_2018_color_smk_cyl&layer=comp',
						'final_price': '1197.00',
					},
					{
						'sku': '6403',
						'name': 'Adams Benches',
						'url': '/catalog/living/benches-and-stools/adams-benches',
						'price': '649.00',
						'price_ranges': '$449 - $649',
						'review_rating': null,
						'image_url': '//rnb.scene7.com/is/image/roomandboard/331137_wood_C',
						'final_price': '449.00',
					},
					{
						'sku': '2625',
						'name': 'Adams Console Tables',
						'url': '/catalog/living/console-tables/adams-console-tables',
						'price': '2399.00',
						'price_ranges': '$749 - $2,399',
						'review_rating': null,
						'image_url': '//rnb.scene7.com/is/image/roomandboard/?layer=0&src=430362_wood_C&layer=1&src=430362_knob_Steel&layer=comp',
						'final_price': '749.00',
					},
					{
						'sku': '676761',
						'name': 'Imogen Full/Queen Duvet in Coal',
						'url': '/clearance/bedroom/duvet-covers-and-shams/676761',
						'sku_url': '/clearance/bedroom/duvet-covers-and-shams/676761',
						'price': '429.00',
						'price_ranges': '$239.99 was $429.00',
						'image_url': '//rnb.scene7.com/is/image/roomandboard/676761',
						'sku_image_url': 'https://rnb.scene7.com/is/image/roomandboard/676761',
						'final_price': '239.99',
						'promotion_flag': 'Clearance',
					},
					{
						'sku': '6402',
						'name': 'Adams Drop-Leaf Table',
						'url': '/catalog/dining-and-kitchen/tables/adams-drop-leaf-table',
						'price': '1799.00',
						'price_ranges': '$1,499 - $1,799',
						'review_rating': 3.5,
						'rating_count': '14',
						'image_url': '//rnb.scene7.com/is/image/roomandboard/156084_wood_BARK',
						'final_price': '1499.00',
					},
					{
						'sku': '2549',
						'name': 'Adams Extension Tables',
						'url': '/catalog/dining-and-kitchen/tables/adams-extension-tables',
						'price': '2299.00',
						'price_ranges': '$1,599 - $2,299',
						'review_rating': 3.5,
						'rating_count': '12',
						'image_url': '//rnb.scene7.com/is/image/roomandboard/624027_wood_SHELL',
						'final_price': '1599.00',
					},
					{
						'sku': '4541',
						'name': 'Adams Glass Door Cabinets',
						'url': '/catalog/living/cabinets-and-armoires/adams-glass-door-cabinets',
						'price': '3099.00',
						'price_ranges': '$1,999 - $3,099',
						'review_rating': 5,
						'rating_count': '1',
						'image_url': '//rnb.scene7.com/is/image/roomandboard/?layer=0&src=012303_wood_SHELL&layer=1&src=012303_knob_Steel&layer=comp',
						'final_price': '1999.00',
					},
					{
						'sku': '2662',
						'name': 'Adams Media Cabinet',
						'url': '/catalog/living/media-storage/adams-media-cabinet',
						'price': '2099.00',
						'price_ranges': '$1,499 - $2,099',
						'review_rating': 4,
						'rating_count': '2',
						'image_url': '//rnb.scene7.com/is/image/roomandboard/?layer=0&src=701593_wood_SHELL&layer=1&src=701593_knob_Steel&layer=comp',
						'final_price': '1499.00',
					},
					{
						'sku': '4161',
						'name': 'Adams Round End Table',
						'url': '/catalog/living/end-tables/adams-round-end-table',
						'price': '599.00',
						'price_ranges': '$499 - $599',
						'review_rating': null,
						'image_url': '//rnb.scene7.com/is/image/roomandboard/780182_wood_C',
						'final_price': '499.00',
					},
				],
				'n_item': 10,
			},
		},
		'page_number': 1,
		'total_page': 2,
		'n_item': 10,
	};

	title;

	uri;

	get uuid() {
		return global.rfk?.uid?.() || Cookies.get('__ruid') || null;
	}

	variationId = undefined;

	widgetId = undefined;

	width1600 = undefined;

	constructor(widgetId, uri, options = {}, context = {}) {
		makeObservable(this, {
			accessToken: observable,
			hasRecos: observable,
			setHasRecos: action.bound,
			mapRfkFieldsToLegacyModel: action.bound,
			recosData: observable.ref,
			setRecosData: action.bound,
			recoItems: computed,
			rid: observable,
			setSku: action.bound,
			sku: observable.ref,
			variationId: observable,
			widgetId: observable,
		});

		const {
			sku = [],
			name,
			contentFields = [
				'sku',
				'name',
				'url',
				'sku_url',
				'price',
				'price_ranges',
				'rating_count',
				'review_rating',
				'image_url',
				'sku_image_url',
				'final_price',
				'promotion_flag',
			],
			title = 'You May Also Like',
			maxItems = 10,
			forceNonSkuImage = false,
			width1600 = false,
		} = options;

		this.contentFields = contentFields;
		this.context = context;
		this.forceNonSkuImage = forceNonSkuImage;
		this.hasRecos = false;
		this.maxItems = maxItems;
		this.sku = sku;
		this.name = name;
		this.title = title;
		this.uri = uri;
		this.widgetId = widgetId;
		this.width1600 = width1600;
	}
}
