import React from 'react';
import { clone } from 'mobx-keystone';

import { withPagePromise } from '~/global/app-config/withPagePromise';
import { HomepageContextProvider } from '~/homepage/Contexts/Homepage.context';
import { HomepageLayout } from '~/homepage/Components/HomepageLayout.pages';
import { PrimaryLayout } from '~/layout/Components/PrimaryLayout';
import { isEngage } from '~/global/global.constants';
import { IPropositions, PERSONALIZATION_SCOPE } from '~/personalization/Personalization.types';
import { PersonalizationStore } from '~/personalization/Stores/Personalization.store';
import { PersonalizationDecisionsModel } from '~/personalization/Models/PersonalizationDecisions.model';
import { PersonalizationDecisionModel } from '~/personalization/Models/PersonalizationDecision.model';

export interface HomepageProps {
	globalDynamicData: any,
	heroPersonalizationDecision?: IPropositions,
}

const Homepage = (props: HomepageProps) => {
	const {
		heroPersonalizationDecision,
	} = props;

	let heroPersonalizationDecisionModel;

	if (heroPersonalizationDecision) {
		heroPersonalizationDecisionModel = new PersonalizationDecisionModel({ decision: heroPersonalizationDecision });
	}

	return (
		<PrimaryLayout fullWidth>
			<HomepageContextProvider props={props}>
				<HomepageLayout heroPersonalizationDecisionModel={heroPersonalizationDecisionModel} />
			</HomepageContextProvider>
		</PrimaryLayout>
	);
};

export default Homepage;

export const getServerSideProps = withPagePromise(async (pageProps) => {
	const {
		cookies,
		globalDynamicData: {
			customerId = undefined,
			adobeLaunchIpAddressClassificationType = undefined,
		} = {},
	} = pageProps;

	let personalizationDecisionData;
	let heroPersonalizationDecision: IPropositions | undefined;

	if (!isEngage && adobeLaunchIpAddressClassificationType !== 'INTERNAL_ROOM_AND_BOARD') {
		const store = new PersonalizationStore({});

		personalizationDecisionData = await store.fetchPersonalizationDecisionServerSide({
			cookies,
			customerId,
			scope: [PERSONALIZATION_SCOPE.HERO],
		});

		store.setDecisionsModel(new PersonalizationDecisionsModel({ decisions: personalizationDecisionData }));
		heroPersonalizationDecision = store.getPersonalizationDecision(PERSONALIZATION_SCOPE.HERO);
	}

	return {
		props: {
			globalDynamicData: pageProps.globalDynamicData,
			...(heroPersonalizationDecision && { heroPersonalizationDecision: clone(heroPersonalizationDecision) }),
			pageType: 'main',
			personalizationScope: [PERSONALIZATION_SCOPE.FLEX_1, PERSONALIZATION_SCOPE.FLEX_2]
		}
	};
});
