import React from 'react';

import { ButtonVariant } from '~/components/Buttons/Types/constants';
import { HORIZONTAL_ALIGNMENT, IHeroWithVideo, VERTICAL_ALIGNMENT } from '~/components/hero/Hero.type';
import { HeroWithVideo } from '~/components/hero/HeroWithVideo';

export const OUTDOOR_VIDEO = ({ clickHandler = () => {} }) => {
	const heroArgs: IHeroWithVideo = {
		buttonText: 'Shop Outdoor',
		buttonLink: '/catalog/outdoor',
		buttonVariant: ButtonVariant.Hollow,
		clickHandler,
		headlineClass: 'tw-text-white',
		headline: 'Create an Oasis',
		headlineButtonAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerHorizontalAlignment: HORIZONTAL_ALIGNMENT.CENTER,
		headlineButtonContainerVerticalAlignment: VERTICAL_ALIGNMENT.TOP,
		nudgeHorizontal: 0,
		nudgeVertical: 15,
		desktopVideoSettings: {
			thumbnailHeight: 400,
			thumbnailImage: 'https://embed-ssl.wistia.com/deliveries/416a5c1e282d8736c233c2fdb2f0cc57.jpg?image_crop_resized=960x400',
			thumbnailPriority: true,
			thumbnailWidth: 960,
			videoId: '8y9pgq6a5p',
		},
		mobileVideoSettings: {
			thumbnailHeight: 960,
			thumbnailImage: 'https://embed-ssl.wistia.com/deliveries/87155daef80b9fce7fffe0a65f227796.jpg?image_crop_resized=960x960',
			thumbnailPriority: true,
			thumbnailWidth: 960,
			videoId: '9bbg68xidd',
		}
	};

	return (
		<HeroWithVideo { ...heroArgs } />
	);
};
