import { BAYLOR_VIDEO } from '~/components/hero/Components/BAYLOR_VIDEO';
import { BEDROOM } from '~/components/hero/Components/BEDROOM';
import { CLEARANCE } from '~/components/hero/Components/CLEARANCE';
import { CLEARANCENOV } from '~/components/hero/Components/CLEARANCE_NOV';
import { DINING } from '~/components/hero/Components/DINING';
import { ELY } from '~/components/hero/Components/ELY';
import { FRESH_FINDS } from '~/components/hero/Components/FRESH_FINDS';
import { LIVING } from '~/components/hero/Components/LIVING';
import { LIVING_VIDEO } from '~/components/hero/Components/LIVING_VIDEO';
import { NEW_ARRIVALS } from '~/components/hero/Components/NEW_ARRIVALS';
import { NEW_ARRIVALS_JAN25 } from '~/components/hero/Components/NEW_ARRIVALS_JAN25';
import { OUTDOOR } from '~/components/hero/Components/OUTDOOR';
import { OUTDOOR_VIDEO } from '~/components/hero/Components/OUTDOOR_VIDEO';
import { HERO_ID } from '~/components/hero/Components/HomepageHeroes.type';

export const Heroes: Record<HERO_ID, any> = {
	'BAYLOR_VIDEO': BAYLOR_VIDEO,
	'BEDROOM': BEDROOM,
	'CLEARANCE': CLEARANCE,
	'CLEARANCE_NOV': CLEARANCENOV,
	'DINING': DINING,
	'ELY': ELY,
	'FRESH_FINDS': FRESH_FINDS,
	'LIVING': LIVING,
	'LIVING_VIDEO': LIVING_VIDEO,
	'NEW_ARRIVALS': NEW_ARRIVALS,
	'NEW_ARRIVALS_JAN25': NEW_ARRIVALS_JAN25,
	'OUTDOOR': OUTDOOR,
	'OUTDOOR_VIDEO': OUTDOOR_VIDEO,
};

export function isHeroID(key: any): key is HERO_ID {
	return key in Heroes;
}
