import { MagicRecosRfkModel } from '~/components/magic-recos-rfk/Models/MagicRecosRfk.model';

import type { ProductRecommendationsData } from '~/components/product-recommendations/ProductRecommendations.types';

export enum DIRECTION {
	NEXT = 'NEXT',
	PREV = 'PREV',
}

export enum EVENTS {
	AFTER_RESIZE = 'afterResize',
	CHANGED = 'changed',
	REACH_EDGE = 'reachEdge',
	READY = 'ready',
}

export enum MOVE_TYPE {
	FREE_SCROLL = 'freeScroll',
	SNAP = 'snap',
	STRICT = 'strict',
}

export enum ProductRecommendationsPageId {
	RecentlyViewed = 'rfkid_recentlyviewed_homepage',
}

export enum ProductRecommendationsTitle {
	RecentlyViewed = 'Recently Viewed',
}

const recentlyViewedModel = new MagicRecosRfkModel(
	ProductRecommendationsPageId.RecentlyViewed,
	'/',
	{
		title: ProductRecommendationsTitle.RecentlyViewed,
	},
	{},
);

const { stubData } = recentlyViewedModel;

recentlyViewedModel.setRecosData(stubData);

const { recoItems } = recentlyViewedModel;

export const recentlyViewedData: ProductRecommendationsData = {
	headerLevel: 2,
	marginAdjust: 106,
	recoItems,
	title: ProductRecommendationsTitle.RecentlyViewed,
};
