import React, { MouseEventHandler } from 'react';

import { TextLink } from '~/components/Buttons/Components/TextLink';
import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { noop } from '~/util/noop';

export const OutdoorSweepsPromo = ({ clickHandler = noop }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-[#CAD39C] tw-flex tw-justify-center tw-text-center tw-px-6 tw-py-10 tw-mb-1">
				<div className="tw-flex tw-flex-col tw-items-center tw-space-y-[15px]">
					<h2 className="tw-heading-3 md:tw-heading-1 tw-font-normal">$4K Outdoor Sweepstakes</h2>
					<p className="tw-body-2">Now through March 17, enter for a chance to win a $4,000 Room & Board gift card* to create your outdoor oasis.</p>
					<Link
						className=""
						color={ButtonColor.Gray}
						href="https://woobox.com/uphj7y"
						isTransparent
						variant={ButtonVariant.Hollow}
						onClick={clickHandler}
						target="_blank"
						linkExternal={true}
					>
					Enter Now
					</Link>
				</div>
			</div>
			<p className="tw-text-gray-300 md:tw-text-2xs tw-text-xs md:tw-text-center">*Void where prohibited. No purchase necessary to enter; a purchase will not increase your chance of winning. Subject to <TextLink href="https://woobox.com/uphj7y/rules" target="_blank" linkExternal={true} ><span className="md:tw-text-2xs tw-text-xs">Official Rules</span></TextLink>.</p>
		</>
	);
};
