import React, { MouseEventHandler } from 'react';

import { Link } from '~/components/Buttons/Components/Link';
import { ButtonColor, ButtonVariant } from '~/components/Buttons/Types/constants';
import { MagicHeaderTag } from '~/components/magic-header';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const OutdoorDesignPromo = ({ clickHandler }: { clickHandler?: MouseEventHandler }) => {
	return (
		<>
			<div className="tw-bg-[#375A0E] tw-flex tw-justify-center tw-text-center md:tw-px-6 tw-px-1 tw-pt-6 tw-pb-10 tw-mb-2">
				<div className="tw-flex tw-flex-col tw-items-center tw-relative">
					<span className="tw-font-extralight md:tw-text-4xl tw-text-xl tw-leading-[1.1] tw-text-white tw-tracking-[0.25em] tw-absolute tw-inline-block tw-bg-[#375A0E] tw-top-0 tw-pl-2 tw-text-center">store event</span>
					<MagicHeaderTag headerLevel={2} className="tw-heading-3 md:tw-heading-1 tw-font-normal tw-text-white tw-border-t tw-border-t tw-border-white tw-pt-8 md:tw-mt-[22px] tw-mt-[15px] tw-mb-4">Outdoor Design Open House + $1,000 Gift Card Giveaway</MagicHeaderTag>
					<span className="tw-body-2 tw-font-semibold tw-text-white tw-mb-4">Saturday, April 12 – Sunday, April 13</span>
					<p className="tw-body-2 tw-text-white tw-mb-4 tw-px-2">Stop in to get inspired, learn from our experts and enter for a chance to win a $1,000 gift card at each store.* </p>
					<Link
						onClick={clickHandler}
						className=""
						color={ButtonColor.White}
						href="/stores"
						isTransparent
						variant={ButtonVariant.Hollow}
					>
					Find Your Store
					</Link>
				</div>
			</div>
			<p className="tw-text-xs md:tw-block tw-mb-0 tw-text-gray-300 tw-text-center">*In-store only. Odds of winning depend on number of entries. Void where prohibited. No purchase necessary. See <TextLink href="https://woobox.com/bf3ivy/rules" target="_blank" linkExternal={true}><span className={'tw-text-xs'}>Official Rules</span></TextLink> for full details and AMOE.</p>
		</>
	);
};
